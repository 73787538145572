import { tableFiltersUrl } from '../../utils/helper'
import request from '../axiosInterceptor'

const api = {
  getAllOrganisations(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/organisation-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getSubsidiaryDetail(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/subsidiary-details?subsidiary_master_id=${data}`,
      method: 'get',
    })
  },
  postAddOrganisation(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/add-organisation`,
      method: 'post',
      data: data,
    })
  },
  getOrganisationUsers(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/organisation-user-list?organisation_master_id=${data?.organization_id}&${tableFiltersUrl(data?.tableFilters)}`,
      method: 'get',
    })
  },
  getSubsidiaryUsers(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/subsidiary-user-profile-list?subsidiary_master_id=${data?.id}&${tableFiltersUrl(data?.tableFilters)}`,
      method: 'get',
    })
  },
  getOrganisationUsersByEmail(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/subsidiary-wise-user-profile?organisation_master_id=${data?.organization_id}&email=${data?.email}&${tableFiltersUrl(data?.tableFilters)}`,
      method: 'get',
    })
  },
  getAllSubsidiaries(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/subsidiary-list?organization_master_id=${data?.organization_id}&${tableFiltersUrl(data?.tableFilters)}`,
      method: 'get',
    })
  },
  postAddSubsidiary(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/add-subsidiary`,
      method: 'post',
      data: data,
    })
  },
  putEditSubsidiary(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/organisation/edit-subsidiary-details`,
      method: 'put',
      data: data,
    })
  },
}

export default api
