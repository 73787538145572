/*
 * Reusable helper functions
 */

import moment from 'moment'
import { ColumnFilters, TableFilters } from './schema/TableColumnSchema'
import { success } from '../api/statusCodes'
import toast from 'react-hot-toast'

// Local storage stuff start
export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, value)
}

export const getLocalStorage = (key: string) => {
  if (localStorage.getItem(key)) {
    return localStorage.getItem(key)
  }
  return null
}

export const clearLocalStorage = () => {
  localStorage.clear()
}
// Local storage stuff end

// Authorizations
export const PermittedRoutes = (sidebarLinks: any) => {
  const permissions = JSON.parse(`${getLocalStorage('permissions')}`)
  const filteredLinks = sidebarLinks?.filter((link: any) => permissions?.some((permission: any) => permission.slug === link.slug))

  return filteredLinks
}

// Date
export const defaultDateFormat = 'DD/MM/YYYY'
export const formatDate = (date: string, format?: string) => {
  return moment(date).format(format || defaultDateFormat)
}

export const tableFiltersUrl = (tableFilters: TableFilters) => {
  const { pagination, columnFilters, sorting } = tableFilters

  let url = ''
  // Pagination url
  url += `page_number=${pagination?.pageIndex}&page_size=${pagination?.pageSize}`
  // Filter url
  if (columnFilters && columnFilters?.length) {
    columnFilters?.map((column: ColumnFilters) => {
      url += `&${column.id}=${column.value}`
    })
  }
  // Sorting url
  if (sorting && sorting?.length) {
    url += `&sort_by=${sorting[0]?.id}&sort_type=${sorting[0]?.desc ? 'desc' : 'asc'}`
  }
  return url
}

// Order
export const orderByPosition = (data: any) => {
  return refLess(data)?.sort((a: any, b: any) => a?.position - b?.position)
}
export const convertToLabelValue = (data: any[], labelKey?: any, valueLey?: string) => {
  if (!data) return []
  return refLess(data)?.map((item: any) => {
    let lab = item?.name;

    if (labelKey && Array.isArray(labelKey)) {
      lab = ''
      labelKey?.map((k: string, ind: number) => {
        lab += item[k] + (ind < labelKey.length - 1 ? ' - ' : '')
        return true
      })
    } else if(labelKey) {
      lab = item[labelKey]
    } 
    
    return {
      label: lab,
      value: valueLey ? item[valueLey] : item?.id,
      ...item,
    }
  })
}

// Reface
export const refLess = (data: any) => {
  if (data) {
    return JSON.parse(JSON.stringify(data))
  }
  return data
}

// String
export const trimString = (str: string | null | undefined) => {
  return (str || '')?.trim()
}

// Http
export const isSuccess = (response: any) => {
  return response?.status === success && response?.data?.meta?.code === success
}
export const showError = (error: any) => {
  if (error) toast.error(error?.data?.meta?.message)
}


// Array
interface ArrayDiffResult {
  added: string[];
  removed: string[];
}

export const diffArrays =(currentArray: string[], newArray: string[]): ArrayDiffResult => {
  const added = newArray.filter(item => !currentArray.includes(item));
  const removed = currentArray.filter(item => !newArray.includes(item));
  return { added, removed };
}


export const hexToRgba = (hex:any, alpha:any = 1) =>  {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Return the RGBA color
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const truncateString = (input: string, len?: number): string => {
  const maxLength = len || 15;
  if (input.length > maxLength) {
      return input.substring(0, maxLength) + '...';
  } else {
      return input;
  }
}