import React, { useState, ReactNode, ErrorInfo, useEffect } from 'react'

interface Props {
  children: ReactNode
}

const ErrorBoundary: React.FC<Props> = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    console.error('Uncaught error:', error, errorInfo)
    setHasError(true)
  }

  useEffect(() => {
    const errorListener = (errorEvent: ErrorEvent) => {
      handleError(new Error(errorEvent.message), { componentStack: errorEvent.filename })
    }

    window.addEventListener('error', errorListener)

    return () => {
      window.removeEventListener('error', errorListener)
    }
  }, [])

  if (hasError) {
    return <h1>Something went wrong.</h1>
  }

  return <>{children}</>
}

export default ErrorBoundary
