import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#456DB3',
    },
    secondary: {
      main: '#ecf0f7',
    },
    error: {
      main: red.A400,
    },
    common: {
      black: '#000',
    },
  },
})

export default theme
