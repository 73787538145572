import axios from 'axios'
import { success, unauthorized } from './statusCodes'
import toast from 'react-hot-toast'
import { getLocalStorage, setLocalStorage } from '../utils/helper'
import { logout } from '../hooks/CookiesHook'

const CancelToken = axios.CancelToken

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  timeout: 60000,
})

const pendingRequests = new Map()

api.interceptors.request.use(
  (config: any) => {
    config.headers = {
      'access-token': `${getLocalStorage('access_token')}`,
    }

    if (config?.url?.includes('asgard/logout')) {
      config.headers = {
        'access-token': `${getLocalStorage('access_token')}`,
        'refresh-token': `${getLocalStorage('refresh_token')}`,
      }
    }

    const requestKey = `${config.method}-${config.url}`
    if (pendingRequests.has(requestKey)) {
      const cancel = pendingRequests.get(requestKey)
      cancel('Cancelled due to a new request')
    }

    if (config?.url?.includes('refresh-token')) {
      config.cancelToken = new CancelToken((cancel) => {
        pendingRequests.set(requestKey, cancel)
      })
    }

    return config
  },
  function (error: any) {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(
  (response: any) => {
    const requestKey = `${response.config.method}-${response.config.url}`
    pendingRequests.delete(requestKey)

    if (response?.data?.meta?.code === unauthorized) {
      // Refresh token API
      axios
        .post(
          `${process.env.REACT_APP_API_BASEURL}v1/asgard/refresh-token`,
          {},
          {
            headers: {
              'access-token': `${getLocalStorage('access_token')}`,
              'refresh-token': `${getLocalStorage('refresh_token')}`,
            },
          },
        )
        .then((res: any) => {
          if (res?.data?.meta?.code === success) {
            setLocalStorage('access_token', res?.data?.data?.access_token)
          } else {
            logout()
          }

          // window.location.reload()
        })
        .catch((error: any) => {
          toast.error(error.response.data.message)
        })
    }
    return response
  },
  function (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message)
    } else if (error.response) {
      if (error.response?.status === unauthorized) {
        // Refresh token API
        axios
          .post(
            `${process.env.REACT_APP_API_BASEURL}v1/asgard/refresh-token`,
            {},
            {
              headers: {
                'access-token': `${getLocalStorage('access_token')}`,
                'refresh-token': `${getLocalStorage('refresh_token')}`,
              },
            },
          )
          .then((res: any) => {
            if (res?.data?.meta?.code === success) {
              setLocalStorage('access_token', res?.data?.data?.access_token)
            } else {
              logout()
            }
          })
          .catch((error: any) => {
            toast.error(error.response.data.message)
          })
      }
    }
    const requestKey = `${error.config.method}-${error.config.url}`
    pendingRequests.delete(requestKey)

    return Promise.reject(error.response)
  },
)

export default api
