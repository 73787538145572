import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { routes } from './routes/routes'
import 'react-phone-input-2/lib/style.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { Toaster } from 'react-hot-toast'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import FullScreenLoader from './components/UI/FullScreenLoader'
import ErrorBoundary from './components/UI/ErrorBoundary'

const router = createBrowserRouter(routes)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.Fragment>
    <ErrorBoundary>
      <Suspense fallback={<FullScreenLoader loading={true} />}>
        <Toaster position="top-right" />
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
          <FullScreenLoader />
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>
  </React.Fragment>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
