import { tableFiltersUrl } from '../../utils/helper'
import request from '../axiosInterceptor'

const api = {
  getAllAdminUsers(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/admin-user-list?${tableFiltersUrl(data)}`,
      method: 'get',
    })
  },
  getAdminModules() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/admin-modules-list`,
      method: 'get',
    })
  },
  getAdminPermissions() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/admin-permission-list`,
      method: 'get',
    })
  },
  postAddAdminUser(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/add-admin-user`,
      method: 'post',
      data: data,
    })
  },
  putEditAdminUser(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/edit-admin-user`,
      method: 'put',
      data: data,
    })
  },
  getCurrentAdminsPermissions(id: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/user-management/admin-user-permission-list?admin_user_id=${id}`,
      method: 'get',
    })
  },
}

export default api
