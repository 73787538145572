import React from 'react'
import { usePromiseTracker } from 'react-promise-tracker'
import ButtonLoader from '../Button/ButtonLoader'

type Props = {
  loading?: boolean
}

const FullScreenLoader = (props: Props) => {
  const { loading } = props
  const { promiseInProgress } = usePromiseTracker()

  return (
    <React.Fragment>
      {promiseInProgress || loading ? (
        <div className="h-screen w-screen absolute bg-[rgba(0,0,0,0.2)] top-0 left-0 z-30 flex justify-center items-center">
          <ButtonLoader fullScreen={true} />
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  )
}

export default FullScreenLoader
