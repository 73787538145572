import request from '../axiosInterceptor'

const api = {
  postLogin(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/asgard/login`,
      method: 'post',
      data: data,
    })
  },
  postOTPVerify(data: any) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/asgard/validate-otp`,
      method: 'post',
      data: data,
    })
  },
  postRefreshToken() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/asgard/refresh-token`,
      method: 'post',
    })
  },
  postLogout() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/asgard/logout`,
      method: 'post',
    })
  },
  //   @TODO for further use
  //
  //   postVerifyOtp(data: any) {
  //     return request({
  //       url: `${process.env.REACT_APP_API_BASEURL}/verify-email`,
  //       method: 'post',
  //       data,
  //       headers: {
  //          'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     })
  //   },
  //   postResendVerification(data) {
  //     return request({
  //       url: `${process.env.REACT_APP_API_BASEURL}/resend-verification`,
  //       method: 'post',
  //       data
  //     })
  //   }
}

export default api
