import request from '../axiosInterceptor'

const api = {
  getMasterDetails(tags?: string) {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/common/master-details${tags || ''}`,
      method: 'get',
    })
  },
  getAllBot() {
    return request({
      url: `${process.env.REACT_APP_API_BASEURL}v1/common/copilots-languages`,
      method: 'get',
    })
  },
}

export default api
