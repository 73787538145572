import React from 'react'
import { LoaderLogo, SmallLogo } from '../../../../assets/images'

type Props = {
  fullScreen?: boolean
}

const ButtonLoader = (props: Props) => {
  const { fullScreen } = props
  return (
    <span className="absolute">
      <img src={fullScreen ? SmallLogo : LoaderLogo} className={`${fullScreen ? 'h-[50px]' : 'h-[24px]'} loader text-white`} />
    </span>
  )
}

export default ButtonLoader
